import React from 'react';
import styled from 'styled-components';

import { BtnMore } from '../Hero/Hero';

import benefits from '../../data/benefits';
import benefitImage1 from '../../assets/images/korzysci1.png';
import benefitImage2 from '../../assets/images/korzysci2.png';
import benefitImage3 from '../../assets/images/korzysci3.png';

const benefitsImages = [benefitImage1, benefitImage2, benefitImage3];

const StyledHeader = styled.h2`
  color: ${props => props.theme.primary};
  font-size: 62px;
  text-align: center;
  font-weight: 100;
  margin-bottom: 90px;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 35px;
    max-width: 210px;
    display: block;
    margin: 0 auto 30px;
    line-height: 41px;
  }
`;

const StyledSection = styled.section`
  padding: 160px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background: ${props =>
    props.backgroundColor ? props.backgroundColor : '#f8f8f8'};

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    padding: 70px 0 100px;
  }
`;

const StyledContainer = styled.div`
  padding: 0 30px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    flex-wrap: wrap;
  }
`;

const Benefit = styled.div`
  width: 375px;
  margin: 0 15px;
  text-align: center;

  &:not(:last-child) {
    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopSm}) {
      margin-bottom: 60px;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 310px;
  }
`;

const StyledHeading = styled.h4`
  font-size: 15px;
  font-weight: 700;
  color: ${props => props.theme.primary};
  margin-bottom: 20px;
  line-height: 22px;

  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

const StyledParagraph = styled.p`
  font-size: 15px;
  color: ${props => props.theme.primary};
  font-weight: 400;
  line-height: 27px;
  font-family: 'Manrope';
`;

const ImageWrapper = styled.div`
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBtnMore = styled(BtnMore)`
  display: inline-block;
  margin: 60px auto 0;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin: 50px auto 0;
  }
`;

const animations = {
  0: 'fade-right',
  1: 'fade-up',
  2: 'fade-left'
};

const Benefits = ({ bgColor, showButton, showButtonUs }) => {
  return (
    <StyledSection backgroundColor={bgColor}>
      <StyledHeader>
        Poznaj <strong>korzyści</strong>
      </StyledHeader>
      <StyledContainer>
        {benefits.map((benefit, index) => (
          <Benefit key={index} data-aos={animations[index]}>
            <ImageWrapper>
              <img loading="lazy" src={benefitsImages[index]} alt={benefit.name} />
            </ImageWrapper>
            <StyledHeading>{benefit.name}</StyledHeading>
            <StyledParagraph>{benefit.text}</StyledParagraph>
          </Benefit>
        ))}
      </StyledContainer>
      {showButton && <StyledBtnMore to="/co-otrzymasz">Zobacz wszystkie</StyledBtnMore>}
      {showButtonUs && <StyledBtnMore to="/sprawdz-nas">Sprawdź nas</StyledBtnMore>}
    </StyledSection>

  );
};

export default Benefits;
