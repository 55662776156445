import React from 'react';
import styled from 'styled-components';
import packages from '../../data/packages';
import scrollTo from 'gatsby-plugin-smoothscroll';

import imageBasic from '../../assets/images/package-basic-icon.png';
import imageStandard from '../../assets/images/package-standard-icon.png';
import imageAdvanced from '../../assets/images/package-advanced-icon.png';

const images = {
  basic: imageBasic,
  standard: imageStandard,
  advanced: imageAdvanced,
};

const StyledHeader = styled.h2`
  display: block;
  width: 100%;
  color: #fff;
  font-size: 62px;
  text-align: center;
  font-weight: 100;
  margin-bottom: 100px;

  strong {
    font-weight: 600;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 39px;
    line-height: 50px;
    margin-bottom: 50px;
  }
`;

const StyledSection = styled.section`
  background-color: #360467;
  padding: 140px 0 184px;
  overflow-x: hidden;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 60px 0 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    flex-wrap: wrap-reverse;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    flex-wrap: wrap;
  }
`;

const ImageWrapper = styled.div`
  height: 192px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const StyledPackageHeader = styled.h3`
  font-size: 27px;
  font-weight: 300;
  color: #fff;
  margin-bottom: 40px;
`;

const StyledButton = styled.a`
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 184px;
  height: 77px;
  text-transform: uppercase;
  color: #350366;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.8px;
  text-decoration: none;
  margin-top: auto;
`;

const StyledList = styled.ul`
  color: #fff;
  margin-bottom: 10px;

  li {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
  }
`;

const PackageWrapper = styled.div`
  padding: 20px 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #3a0b96;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 20px 60px 0;
  }
`;

const StyledPackage = styled.div`
  width: 330px;
  display: flex;

  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 100%;
  }

  &:first-child {
    padding: 15px 0 30px;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopSm}) {
      padding: 0;
      margin: 15px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin: 0;
    }
  }

  &:nth-child(2) {
    width: 375px;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopSm}) {
      order: 3;
      margin: 15px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      order: initial;
      margin: 0;
      width: 100%;
    }

    ${PackageWrapper} {
      background-color: #efba35;
    }

    ${StyledPackageHeader} {
      font-size: 37px;
      margin-bottom: 60px;
    }

    ${StyledList} {
      margin-bottom: 70px;

      li {
        font-size: 16px;
      }
    }

    ${StyledButton} {
      background-color: #e56b2a;
      color: #fff;
    }
  }

  &:last-child {
    padding: 30px 0 15px;

    @media screen and (max-width: ${props =>
        props.theme.breakpoints.desktopSm}) {
      padding: 0;
      margin: 15px;
    }

    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      margin: 0;
    }

    ${StyledList} {
      margin-bottom: 50px;
    }
  }
`;

const handleClick = () => {
  scrollTo('#kontakt');
};

const animations = {
  0: 'fade-right',
  1: 'fade-in',
  2: 'fade-left',
};

const Packages = ({ setTxtValue }) => {
  return (
    <StyledSection>
      <StyledHeader>
        Wybierz swój <strong>pakiet</strong>
      </StyledHeader>
      <StyledContainer>
        {packages.map((packageItem, index) => (
          <StyledPackage key={index} data-aos={animations[index]}>
            <PackageWrapper>
              <ImageWrapper>
                <img
                  src={images[packageItem.name.toLowerCase()]}
                  alt={packageItem.name}
                  loading="lazy"
                />
              </ImageWrapper>
              <StyledPackageHeader>{packageItem.name}</StyledPackageHeader>
              <StyledList>
                {packageItem.features.map(feature => (
                  <li>{feature}</li>
                ))}
              </StyledList>
              <StyledButton
                onClick={() => {
                  handleClick(packageItem.name);
                  setTxtValue(
                    `Jestem zainteresowany pakietem ${packageItem.name}. Chętnie poznam szczegóły waszej oferty : )`
                  );
                }}
              >
                Wybierz
              </StyledButton>
            </PackageWrapper>
          </StyledPackage>
        ))}
      </StyledContainer>
    </StyledSection>
  );
};

export default Packages;
